import { useState, useEffect } from "react";

export type UseFetch = (
  url: string,
  opts?: RequestInit
) => [any, boolean, boolean];

export const useFetch: UseFetch = (url, opts) => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    setLoading(true);
    setResponse(null);
    fetch(url, opts)
      .then(async (res: any) => {
        setLoading(false);
        setResponse(await res.json());
      })
      .catch(() => {
        setHasError(true);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [url]);
  return [response, loading, hasError];
};
