import React, { useMemo } from "react";
import Item from "./Item";
import { Item as ItemType, HandleOpenModal } from "../../globalTypes/AppData";
import { List as MuiList, makeStyles } from "@material-ui/core";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { itemHoverBackground } from "../../theme";
import { indexBy, prop, map } from "ramda";

const useStyles = makeStyles(() => ({
  listItem: {
    "& :hover:not(.MuiListItemSecondaryAction-root):not(.MuiChip-root):not(.MuiChip-label)": {
      backgroundColor: itemHoverBackground,
      cursor: "pointer",
    },
  },
}));

type Props = {
  items: ItemType[];
  handleOpenModal: HandleOpenModal;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  handleDelete: (index: number) => void;
  handleClone: (index: number) => void;
  setScrollTo: (variable: string) => void;
};

const List: React.FC<Props> = ({
  items,
  handleDelete,
  handleClone,
  handleOpenModal,
  moveCard,
  setScrollTo,
}) => {
  const classes = useStyles();

  // @ts-ignore
  const itemsByNumbers = useMemo(() => indexBy(prop("number"), items), [
    items,
  ]);

  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        <MuiList>
          {items.map((item: ItemType, index: number) => {
            const dependsOnNumbers = map(
              (number) => itemsByNumbers[number],
              item.dependsOnNumbers
            );

            return (
              <div className={classes.listItem} key={item.variable}>
                <Item
                  index={index}
                  item={item}
                  dependsOnNumbers={dependsOnNumbers}
                  handleOpenModal={handleOpenModal}
                  handleDelete={handleDelete}
                  handleClone={handleClone}
                  moveCard={moveCard}
                  setScrollTo={setScrollTo}
                />
              </div>
            );
          })}
        </MuiList>
      </DndProvider>
    </div>
  );
};

export default List;
