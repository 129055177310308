import { Button, makeStyles } from "@material-ui/core";
import { keys } from "ramda";
import React, { useEffect, useState } from "react";
import createPersistedState from "use-persisted-state";

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginRight: theme.spacing(2),
  },
}));

type Props = {
  handlePasteFromClipboard: () => void;
};

const Clipboard: React.FC<Props> = ({ handlePasteFromClipboard }) => {
  const classes = useStyles();

  const useClipboardState = createPersistedState("casigateClipboard");
  const [clipboard, setClipboard] = useClipboardState({});
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(keys(clipboard).length);
  }, [clipboard]);
  return (
    <div>
      <span className={classes.buttons}>{count} items in clipboard</span>
      <Button
        onClick={() => handlePasteFromClipboard()}
        color="inherit"
        variant="outlined"
        className={classes.buttons}
      >
        Paste from clipboard
      </Button>
      <Button
        onClick={() => setClipboard({})}
        color="inherit"
        variant="outlined"
        className={classes.buttons}
      >
        Reset clipboard
      </Button>
    </div>
  );
};

export default Clipboard;
