import {
    AppBar,
    Button,
    CircularProgress,
    fade,
    InputAdornment,
    makeStyles,
    TextField,
    Toolbar,
    Typography
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import useHashParam from "use-hash-param";
import { HandleOpenModal, Item } from "../../globalTypes/AppData";
import Clipboard from "./Clipboard";
import SelectCountry from "./SelectCountry";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },

  autocomplete: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiOutlinedInput-root ": {
      color: theme.palette.common.white,
      padding: theme.spacing(1 / 4, 0.5),
    },
  },
  search: {
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },

  loading: {
    color: theme.palette.common.white,
  },

  addQuestion: {
    marginRight: theme.spacing(2),
  },

  actionButtons: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flex: 1,
  },
}));

type Props = {
  country: number;
  disabled: boolean;
  items: Item[];
  isSaving: boolean;
  handleChangeCountry: (event: any) => void;
  handleOpenModal: HandleOpenModal;
  handleSave: () => void;
  handlePasteFromClipboard: () => void;
};

const TopBar: React.FC<Props> = ({
  country,
  disabled,
  items,
  isSaving,
  handleOpenModal,
  handleChangeCountry,
  handleSave,
  handlePasteFromClipboard,
}) => {
  const classes = useStyles();
  const [, setScrollTo] = useHashParam("scrollTo");

  const handlerSearch = (event: any, value: Item | null) => {
    if (value) {
      setScrollTo(value.variable);
    }
  };

  return (
    <AppBar>
      <Toolbar>
        <Typography className={classes.title} variant="h6" noWrap>
          Otázky z Populace
        </Typography>
        <div className={classes.search}>
          <Autocomplete
            options={items}
            getOptionLabel={(option) => `${option.variable}: ${option.name}`}
            style={{ width: 300 }}
            onChange={handlerSearch}
            className={classes.autocomplete}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </div>

        <SelectCountry
          country={country}
          handleChangeCountry={handleChangeCountry}
        />
        <div className={classes.actionButtons}>
          <Clipboard handlePasteFromClipboard={handlePasteFromClipboard} />
          <Button
            onClick={() => handleOpenModal()}
            color="inherit"
            variant="outlined"
            className={classes.addQuestion}
          >
            add Question
          </Button>
          <Button
            color="inherit"
            variant="outlined"
            onClick={() => handleSave()}
            disabled={disabled}
          >
            {isSaving && (
              <span>
                <CircularProgress
                  size={30}
                  className={classes.loading}
                  disableShrink
                />
                &nbsp;&nbsp;&nbsp;
              </span>
            )}
            save
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
