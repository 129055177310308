import { useEffect } from "react";
import useHashParam from "use-hash-param";
import { scroller } from "react-scroll";

const tryToScroll = async (scrollTo: string, count: number = 0) => {
  if (scroller.get(scrollTo)) {
    scroller.scrollTo(scrollTo, { offset: -70 });
  } else {
    setTimeout(() => {
      if (scroller.get(scrollTo)) {
        scroller.scrollTo(scrollTo, { offset: -70 });
      } else if (count < 10) {
        tryToScroll(scrollTo, ++count);
      }
    }, 100);
  }
};

export type useScrollTo = () => void;

export const useScrollTo: useScrollTo = () => {
  const [scrollTo] = useHashParam("scrollTo");

  useEffect(() => {
    tryToScroll(scrollTo);
  }, [scrollTo]);
};
