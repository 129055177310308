import { KeycloakConfig } from "keycloak-js";

type AppConfig = {
  keycloak: KeycloakConfig;
  backendUrl: string;
};

export let appConfig: AppConfig = {
  keycloak: {
    url: "",
    realm: "",
    clientId: "",
  },
  backendUrl: "",
};

export function initialSetAppConfig(config: any) {
  appConfig = {
    keycloak: {
      url: config.REACT_APP_KEYCLOAK_URL,
      realm: config.REACT_APP_KEYCLOAK_REALM,
      clientId: config.REACT_APP_KEYCLOAK_CLIENT_ID,
    },
    backendUrl:
      config.REACT_APP_BACKEND_URL || "https://casigate-test.ipsos.cz/",
  };
}
