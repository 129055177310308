import React from "react";
import {Button, Dialog, DialogActions, DialogContent, makeStyles, MenuItem,} from "@material-ui/core";
import {Form} from "react-final-form";
import {Autocomplete, makeRequired, makeValidate, Select, Switches, TextField,} from "mui-rff";
import {find, pipe, pluck, propEq, uniq} from "ramda";
import {
  BooleanSchema,
  NotRequiredArraySchema,
  NumberSchema,
  StringSchema,
} from 'yup';
import * as yup from "yup";
import {Item} from "../../globalTypes/AppData";

const useStyles = makeStyles((theme) => ({
  dialog: {
    height: "100%",
    "& .MuiTextField-root": {
      marginBottom: theme.spacing(1),
    },
  },
}));

type Props = {
  open: boolean;
  initialValues: Item | {};
  items: Item[];
  handleClose: () => void;
  onSubmit: (values: any) => void;
};

// has to be typeguard
const isValidJsonOrEmptyString = (text: string): text is string => {
  if(!text) {
    return true;
  }

  try {
    JSON.parse(text);
    return true;
  } catch (e) {
    console.warn(e);
    return false;
  }
};

type SchemaByType<T> =
  (T extends (infer AT)[] ? NotRequiredArraySchema<AT>
    : (T extends string ? StringSchema<string>
      : (T extends number ? NumberSchema<number>
        : (T extends boolean ? BooleanSchema<boolean>
          : (T extends (string | null) ? StringSchema<string | undefined | null>
            : (T extends (number | null) ? NumberSchema<number | undefined | null>
              : (T extends (boolean | null) ? BooleanSchema<boolean | undefined | null>
                : never
                )
              )
            )
          )
        )
      )
    )

type FormSchemaDefinition<T> = {
  [P in keyof T]: SchemaByType<T[P]>
};

const formSchemaDefinitions: FormSchemaDefinition<Item> = {
  variable: yup.string().required("Toto pole je povinné"),
  name: yup.string().required("Toto pole je povinné"),
  section: yup
    .string()
    .required("Toto pole je povinné (u nové sekce, je potřeba dát enter)"),
  nipoCode: yup.string().required("Toto pole je povinné"),
  capiNipoEpilog: yup.string().nullable(),
  casiNipoEpilog: yup.string().nullable(),
  sampleData: yup.string().required("Toto pole je povinné"),
  capiSampleData: yup.string().nullable(),
  number: yup.number().required("Toto pole je povinné"),
  dependsOnNumbers: yup.array(yup.number().required()),
  dependencyCondition: yup.string().nullable(),
  hidden: yup.boolean().required(),
  nipoTimeout: yup.string().required("Toto pole je povinné"),
  socDem: yup.boolean().required(),
  additionalData: yup.string().test(
    "Validní JSON",
    "Pole AdditionalData neobsahuje validní JSON. Více informací je vypsáno v konzoli.",
    isValidJsonOrEmptyString
  ).nullable(),
  quotaVariable: yup.string().nullable(),
  nirCategoryId: yup.number().required("Toto pole je povinné"),
  sectionC: yup.number().required("Toto pole je povinné"),
};

const formSchema = yup.object().shape(formSchemaDefinitions);

const validate = makeValidate(formSchema);
const required = makeRequired(formSchema);

const Modal: React.FC<Props> = ({
  open,
  items,
  initialValues,
  handleClose,
  onSubmit,
}) => {
  const classes = useStyles();
  const sections = pipe(pluck("section"), uniq)(items);
  // @ts-ignore
  const initialDependsOnNumbers = initialValues.dependsOnNumbers
    ? // @ts-ignore
      initialValues.dependsOnNumbers.map((number) => {
        return find(propEq("number", number), items);
      })
    : [];

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={open}
      onClose={handleClose}
      className={classes.dialog}
    >
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={{
          dependsOnNumbers: [],
          hidden: false,
          socDem: false,
          ...initialValues,
        }}
        render={({ handleSubmit, submitting, pristine, form }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                label="VAR"
                name="variable"
                required={required.variable}
              />
              <TextField
                label="Číslo"
                name="number"
                required={required.number}
              />
              <TextField
                label="Otázka"
                name="name"
                required={required.name}
                multiline
              />
              <Autocomplete
                label="Sekce"
                name="section"
                required={required.section}
                options={sections}
                freeSolo
                textFieldProps={{
                  // Autocomplete onChange reaguje pouze na výběr nebo enter, proto tento hack
                  // https://gitlab.commity.cz/scripting-tool/qre-builder/-/issues/978
                  // @ts-ignore
                  onChange: ({target}) => target?.value && form.change("section", target.value)
                }}
              />
              <TextField
                label="Nipo kód"
                name="nipoCode"
                required={required.nipoCode}
                multiline
              />
              <TextField
                  label="Additional data"
                  name="additionalData"
                  required={required.additionalData}
                  multiline
              />
              <TextField
                label="Quota variable"
                name="quotaVariable"
                required={required.quotaVariable}
              />
              <TextField
                  label="CASI/CAWI Nipo epilog"
                  name="casiNipoEpilog"
                  required={required.casiNipoEpilog}
                  multiline
              />
              <TextField
                  label="CAPI Nipo epilog"
                  name="capiNipoEpilog"
                  required={required.capiNipoEpilog}
                  multiline
              />
              <TextField
                label="Sample data"
                name="sampleData"
                required={required.sampleData}
              />
              <TextField
                  label="CAPI Sample data"
                  name="capiSampleData"
                  required={required.capiSampleData}
              />
              <Autocomplete
                required={required.dependsOnNumbers}
                label="Závisí na"
                name="dependsOnNumbers"
                options={items}
                getOptionValue={(option) => option.number}
                getOptionLabel={(option) =>
                  `${option.variable}: ${option.name}`
                }
                defaultValue={initialDependsOnNumbers}
                disableCloseOnSelect={true}
                multiple
              />
              <TextField
                label={"Dependency condition"}
                name={"dependencyCondition"}
                required={required.dependencyCondition}
                multiline
              />
              <Select
                  name="nipoTimeout"
                  label="Autoaktualizace"
                  required={required.nipoTimeout}
                  formControlProps={{margin: "normal"}}
              >
                <MenuItem value="NOT_UPDATING">Neaktualizuje se</MenuItem>
                <MenuItem value="TWELVE_MONTHS">1x ročně</MenuItem>
                <MenuItem value="SIX_MONTHS">2x ročně</MenuItem>
              </Select>
              <Select
                  name="nirCategoryId"
                  label="Kategorie v Instant Research"
                  required={required.nirCategoryId}
                  formControlProps={{margin: "normal"}}
              >
                <MenuItem value="0">N/A</MenuItem>
                <MenuItem value="1">Základní sociodemografické údaje</MenuItem>
                <MenuItem value="2">Zaměstnání</MenuItem>
                <MenuItem value="3">Domácnost</MenuItem>
                <MenuItem value="4">Finance</MenuItem>
                <MenuItem value="5">Nákupy</MenuItem>
                <MenuItem value="6">Konzumace</MenuItem>
                <MenuItem value="7">Telekomunikace</MenuItem>
                <MenuItem value="8">PC, Internet, TV</MenuItem>
                <MenuItem value="9">Lifestyle, cestování, politika</MenuItem>
                <MenuItem value="10">Zdravotní stav</MenuItem>
              </Select>
              <Select
                  name="sectionC"
                  label="Sekce C"
                  required={required.sectionC}
                  formControlProps={{margin: "normal"}}
              >
                <MenuItem value="0">Osobní dotazník (není ze sekce C)</MenuItem>
                <MenuItem value="1">Sekce C - v NIR zobrazit pouze pro Ipsos</MenuItem>
                <MenuItem value="2">Sekce C - v NIR zobrazit všem (i klientům)</MenuItem>
              </Select>
              <Switches
                name="socDem"
                data={{ label: "SocDem", value: false }}
              />
              <Switches
                name="hidden"
                data={{ label: "Hidden", value: false }}
              />
            </DialogContent>
            <DialogActions>
              <Button type="button" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={submitting || pristine}
              >
                Ok
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

export default Modal;
