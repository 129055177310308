import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import Keycloak from "keycloak-js";
import { appConfig, initialSetAppConfig } from "./config";
import App from "./App";
import theme from "./theme";
import * as serviceWorker from "./serviceWorker";

fetch("/config.json")
  .then((resp) => resp.json())
  .then((config) => {
    initialSetAppConfig(config);
    const keycloak = Keycloak(appConfig.keycloak);
    keycloak
      .init({
        onLoad: "login-required",
        checkLoginIframe: false,
      })
      .then(function (authenticated: any) {
        // @ts-ignore
        localStorage.setItem("keycloakToken", keycloak.token);
        ReactDOM.render(
          <React.StrictMode>
            <ThemeProvider theme={theme}>
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              <App />
            </ThemeProvider>
          </React.StrictMode>,
          document.querySelector("#root")
        );
      })
      .catch(function () {
        alert("Failed to initialize");
      });
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
