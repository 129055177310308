import React, {memo} from "react";
import {Avatar, Chip, makeStyles, Tooltip} from "@material-ui/core";

import {Item as ItemType} from "../../globalTypes/AppData";
import {equals} from "ramda";

const useStyles = makeStyles((theme) => ({
    chipsForItem: {
        margin: theme.spacing(0.5, 1),
        maxWidth: 140,

        "& .MuiChip-avatar": {
            width: "fit-content",
            fontSize: 10,
            fontWeight: "bold",
            color: theme.palette.common.black,
            borderRadius: theme.spacing(1),
            padding: theme.spacing(0, .5),
            height: 20
        },

    },
}));

type Props = {
    dependsOnNumbers: ItemType[];
    handleClick: (variable: string) => void;
};

const areEqual = (newProps: Props, oldProps: Props) => {
    if (
        equals(newProps, oldProps) ||
        (newProps.dependsOnNumbers.length === 0 &&
            oldProps.dependsOnNumbers.length === 0)
    ) {
        return true;
    }

    return false;
};

const ChipsForItems: React.FC<Props> = memo(
    ({dependsOnNumbers, handleClick}) => {
        const classes = useStyles();

        return (
            <>
                {dependsOnNumbers.map((item) => {
                    if (!item) {
                        return null;
                    }
                    return (
                        <Tooltip
                            title={`${item.variable}: ${item.name}`}
                            arrow
                            key={item.variable}
                        >
              <span>
                <Chip
                    className={classes.chipsForItem}
                    avatar={<Avatar>{item.variable}</Avatar>}
                    label={item.name}
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        handleClick(item.variable);
                    }}
                />
              </span>
                        </Tooltip>
                    );
                })}
            </>
        );
    },
    areEqual
);

export default ChipsForItems;
