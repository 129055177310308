import React from "react";
import {TextField, MenuItem, makeStyles} from "@material-ui/core";

type Props = {
    country: number | string;
    handleChangeCountry: (event: any) => void;
};

const useStyles = makeStyles((theme) => ({
    countrySelect: {
        '& .MuiFormLabel-root': {
            color: theme.palette.common.white
        },
        '& .MuiSelect-select': {
            color: theme.palette.common.white

        }
    },
}));

const SelectCountry: React.FC<Props> = ({handleChangeCountry, country}) => {

    const classes = useStyles();
    return (
        <TextField
            id="select"
            label="Jazyk"
            onChange={handleChangeCountry}
            value={country}
            select
            className={classes.countrySelect}
        >
            <MenuItem value="1">CZ</MenuItem>
            <MenuItem value="2">SK</MenuItem>
            <MenuItem value="3">HU</MenuItem>
            <MenuItem value="4">AT</MenuItem>
        </TextField>
    );
};

export default SelectCountry;
