import {createMuiTheme, responsiveFontSizes} from '@material-ui/core/styles';
import {blueGrey, grey, lightBlue, lightGreen, teal} from '@material-ui/core/colors';

const fontFamily = "'Roboto', 'Helvetica', 'Arial', sans-serif";

export const topBarHeight = 64;

export const itemHoverBackground = grey['100']
export const sectionChipBackground = lightGreen['100']

let theme = createMuiTheme({
    palette: {
        primary: {
            main: teal['500'],
            light: teal['200'],
            dark: teal['900'],
        },

        secondary: {
            main: lightBlue['700'],
            dark: lightBlue['900'],
            light: lightBlue['200'],
        },

        text: {
            primary: blueGrey['800'],
        },

        background: {
            default: '#FFFFFF',
        },

        common: {
            white: '#FFFFFF',
            black: '#37474f',
        },
    },

    typography: {
        fontFamily: fontFamily,

        body1: {
            fontSize: 16,
            fontWeight: 300,
        },
        body2: {
            fontSize: 14,
        },
        caption: {
            fontSize: 12,
        },
    },

    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: 10,
            },
        },

        MuiContainer: {
            disableGutters: {
                paddingLeft: 16,
                paddingRight: 16,
            },
        },

        MuiSelect: {
            root: {
                paddingTop: 4,
                paddingBottom: 4,
            },
        },

        MuiInput: {
            formControl: {
                'label + &': {
                    marginTop: 12,
                },
            },
        },

        MuiButton: {
            contained: {
                fontSize: 10,
            },
        },

        MuiCardContent: {
            root: {
                padding: '2px 16px',
            },
        },

        MuiChip: {
            label: {
                fontSize: 12,
            },
        },

        MuiInputLabel: {
            root: {
                fontSize: 16,
                fontWeight: 'bold',
            },
        },
    },
});

theme = responsiveFontSizes(theme);

export default theme;
