import {
  Button,
  Chip,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Popper,
  Typography,
} from "@material-ui/core";
import {
  ControlPointDuplicate,
  Delete,
  FileCopy,
  Reorder,
} from "@material-ui/icons";
import { equals, has, lensProp, omit, set } from "ramda";
import React, { memo, useRef, useState } from "react";
import Scroll from "react-scroll";
import createPersistedState from "use-persisted-state";
import { HandleOpenModal, Item as ItemType } from "../../globalTypes/AppData";
import { sectionChipBackground } from "../../theme";
import ChipsForItems from "./ChipsForItems";
import { useDrag, useDrop } from "./dndItem";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px dashed gray",
    padding: theme.spacing(0.5, 1 / 4, 0.5, 1 / 4),
    marginBottom: ".5rem",
    backgroundColor: theme.palette.background.default,
  },

  listItemText: {
    cursor: "pointer",
    maxWidth: "calc(100% - 200px)", // (- button width, chip container and some reserve pxs)
  },

  sectionChip: {
    backgroundColor: sectionChipBackground,
  },

  secondaryActions: {
    top: theme.spacing(3),
  },

  deleteButton: {
    padding: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
  },

  popper: {
    padding: theme.spacing(1),
  },

  actionButtons: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

type Props = {
  index: number;
  item: ItemType;
  dependsOnNumbers: ItemType[];
  handleOpenModal: HandleOpenModal;
  handleDelete: (index: number) => void;
  handleClone: (index: number) => void;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  setScrollTo: (variable: string) => void;
};

const Item: React.FC<Props> = memo(
  ({
    handleOpenModal,
    handleDelete,
    handleClone,
    index,
    item,
    dependsOnNumbers,
    moveCard,
    setScrollTo,
  }) => {
    const classes = useStyles();

    const useClipboardState = createPersistedState("casigateClipboard");
    const [clipboard, setClipboard] = useClipboardState({});

    const ref = useRef(null);
    const [, drop] = useDrop(ref, index, moveCard);
    const [{ isDragging }, drag, preview] = useDrag(index);
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = React.useState(false);

    const opacity = isDragging ? 0 : 1;
    const handleDeleteClick = (event: any) => {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    const handleAddToClipboard = () => {
      if (!has(item.variable, clipboard)) {
        setClipboard(
          set(lensProp(item.variable), omit(["version"], item), clipboard)
        );
      }
    };

    drag(drop(ref));

    return (
      <Scroll.Element name={`${item.variable}`}>
        <ListItem ref={preview} className={classes.root} style={{ opacity }}>
          <IconButton ref={ref}>
            <Reorder />
          </IconButton>
          <ListItemText
            onClick={() => handleOpenModal(index, item)}
            primary={`${item.variable}: ${item.name}`}
            secondary={
              dependsOnNumbers.length > 0 && (
                <ChipsForItems
                  dependsOnNumbers={dependsOnNumbers}
                  handleClick={setScrollTo}
                />
              )
            }
            className={classes.listItemText}
          />
          <ListItemSecondaryAction
            style={{ display: opacity ? undefined : "none" }}
            className={
              dependsOnNumbers.length > 0 ? classes.secondaryActions : ""
            }
          >
            <Chip className={classes.sectionChip} label={item.section} />
            <Popper open={open} anchorEl={anchorEl} placement={"bottom"}>
              <Paper className={classes.popper}>
                <Typography variant={"h6"}>
                  Opravdu chcete smazat otázku?
                </Typography>
                <div className={classes.actionButtons}>
                  <Button onClick={() => handleClose()}>Ne</Button>
                  <Button color={"primary"} onClick={() => handleDelete(index)}>
                    Ano
                  </Button>
                </div>
              </Paper>
            </Popper>
            {!has(item.variable, clipboard) && (
              <IconButton
                onClick={() => handleAddToClipboard()}
                edge="end"
                aria-label="add to clipboard"
                className={classes.deleteButton}
              >
                <ControlPointDuplicate />
              </IconButton>
            )}
            <IconButton
              onClick={() => handleClone(index)}
              edge="end"
              aria-label="clone"
              className={classes.deleteButton}
            >
              <FileCopy />
            </IconButton>
            <IconButton
              onClick={(event) => handleDeleteClick(event)}
              edge="end"
              aria-label="delete"
              className={classes.deleteButton}
            >
              <Delete />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </Scroll.Element>
    );
  },
  equals
);

export default Item;
