import React from "react";
import Layout from "./uiState/Layout";
import { useScrollTo } from "./hooks/useScrollTo";

const App = () => {
  useScrollTo();

  return <Layout />;
};

export default App;
