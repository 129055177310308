import {Container, makeStyles} from "@material-ui/core";
import {
  append,
  equals,
  findLastIndex,
  inc,
  includes,
  insert,
  lensIndex,
  map,
  move,
  omit,
  pluck,
  propEq,
  remove,
  set,
  values,
} from "ramda";
import React, {useCallback, useEffect, useReducer, useState} from "react";
import useHashParam from "use-hash-param";
import {appConfig} from "../config";
import {HandleOpenModal, Item as ItemType} from "../globalTypes/AppData";
import {useFetch} from "../hooks/useFetch";
import {topBarHeight} from "../theme";
import List from "./components/List";
import Modal from "./components/Modal";
import TopBar from "./components/TopBar";

const useStyles = makeStyles(() => ({
  list: {
    paddingTop: topBarHeight,
  },
}));

const setNewVar = (
  item: ItemType,
  key: number,
  variables: string[]
): ItemType => {
  const newVar = `${item.variable}_${key}`;
  if (includes(newVar, variables)) {
    return setNewVar(item, ++key, variables);
  }
  return {
    ...item,
    variable: newVar,
  };
};

const reducers = {
  setItems: (state: ItemType[], { items }: any) => items as ItemType[],
  delete: (state: ItemType[], { index }: any) => remove(index, 1, state),
  create: (state: ItemType[], { values }: any) => {
    const lastInSection = findLastIndex(propEq("section", values.section))(
      state
    );

    if (lastInSection >= 0) {
      return insert(inc(lastInSection), values, state);
    }
    return append(values, state);
  },
  update: (state: ItemType[], { editedIndex, values }: any) =>
    set(lensIndex(editedIndex), values, state),
  move: (state: ItemType[], { dragIndex, hoverIndex }: any) =>
    move(dragIndex, hoverIndex, state),
  clone: (state: ItemType[], { index }: any) => {
    const variables = pluck("variable", state);
    const newItem = setNewVar(state[index], 0, variables);

    return insert(++index, omit(["version"], newItem), state);
  },
  pasteFromClipboard: (state: ItemType[]) => {
    const variables = pluck("variable", state);
    const clipboardState = localStorage.getItem("casigateClipboard");

    const clipboard = clipboardState ? JSON.parse(clipboardState) : {};
    const newItems = map(
      (item) => setNewVar(item, 0, variables),
      values(clipboard)
    );

    return [...state, ...newItems];
  },
};

function reducer(state: ItemType[], action: any) {
  // @ts-ignore
  const f = reducers[action.type];
  if (!f) {
    console.error("Unknown action", action);
    return state;
  }

  return f(state, action);
}

const Layout = () => {
  const [items, dispatch] = useReducer(reducer, []);

  /* state */
  const [initialItems, setInitialItems] = useState<ItemType[]>([]);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [editedIndex, setEditedIndex] = useState<number>(-1);
  const [country, setCountry] = useState<number>(1);
  const [initialCountry, setInitialCountry] = useState<number>(country);
  const [initialValues, setInitialValues] = useState<any>({});
  const [modalOpen, setModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [saveTimeout, setSaveTimeout] = useState<
    ReturnType<typeof setTimeout>
  >();
  const classes = useStyles();

  const h = (event: any) => {
    if (!disabled) {
      event.preventDefault();
    }
  };

  /* use */
  const [, setScrollTo] = useHashParam("scrollTo");

  useEffect(() => {
    window.addEventListener("beforeunload", h);
    return () => window.removeEventListener("beforeunload", h);
  });

  const [response, loading, hasError] = useFetch(
    `${appConfig.backendUrl}/api/question-nipo/by-country/${country}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("keycloakToken")}`,
      },
    }
  );

  /* handler */
  const changeItems = (mutator: any, initItems?: ItemType[]) => {
    dispatch(mutator);

    if (initItems) {
      setInitialItems(initItems);
    }
  };

  const handlePasteFromClipboard = useCallback(() => {
    changeItems({
      type: "pasteFromClipboard",
    });
  }, []);

  const handleDelete = useCallback((index: number) => {
    changeItems({
      type: "delete",
      index,
    });
  }, []);

  const handleClone = useCallback((index: number) => {
    changeItems({
      type: "clone",
      index,
    });
  }, []);

  const handleOpenModal: HandleOpenModal = useCallback((index = -1, item) => {
    if (index === -1) {
      setInitialValues({});
    } else {
      setInitialValues(item);
    }
    setEditedIndex(index);
    setModalOpen(true);
  }, []);

  const onSubmit = useCallback(
    (values: ItemType) => {
      if (editedIndex === -1) {
        changeItems({
          type: "create",
          values,
        });
      } else {
        changeItems({
          type: "update",
          editedIndex,
          values,
        });
      }
      setModalOpen(false);
      setScrollTo(values.variable);
    },
    [editedIndex, setScrollTo]
  );

  const handleSave = (data = items, discardResponse = false) => {
    setIsSaving(true);
    fetch(`${appConfig.backendUrl}/api/question-nipo/by-country/${country}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("keycloakToken")}`,
        "content-type": "application/json",
      },
      method: "post",
      body: JSON.stringify(data),
    })
      .then(async (res: any) => {
        setIsSaving(false);
        const responseJson = await res.json();
        if (!discardResponse) {
          changeItems(
            {
              type: "setItems",
              items: responseJson,
            },
            responseJson
          );
        }
        // při změně země, nemusím chtít načítat položky co se mi vrátí v odpovědi
      })
      .catch(() => {
        setIsSaving(false);
        alert("nepodařilo se uložit změny");
      });
  };

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    changeItems({ type: "move", dragIndex, hoverIndex });
  }, []);

  const handleChangeCountry = (event: any) => {
    if (!disabled && window.confirm("Chcete uložit změny?")) {
      handleSave(items, true);
    }
    setCountry(event.target.value);
  };

  /* Effect */
  useEffect(() => {
    const areObjectsSame = equals(initialItems, items);
    if (saveTimeout) {
      clearTimeout(saveTimeout);
    }
    if (!areObjectsSame) {
      const timeout = setTimeout(function () {
        handleSave(items);
        setSaveTimeout(undefined);
      }, 5000);
      setSaveTimeout(timeout);
    }

    if (areObjectsSame !== disabled) {
      setDisabled(areObjectsSame);
    }
    // eslint-disable-next-line
  }, [items, initialItems, disabled, setDisabled]);

  useEffect(() => {
    if (
      response &&
      loading === false &&
      hasError === false &&
      (items.length === 0 ||
        (initialCountry !== country && !equals(response, initialItems)))
    ) {
      setInitialCountry(country);
      changeItems(
        {
          type: "setItems",
          items: response,
        },
        response
      );
    }
    // eslint-disable-next-line
  }, [response]);

  return (
    <Container maxWidth="md">
      {modalOpen && (
        <Modal
          open={modalOpen}
          handleClose={() => setModalOpen(false)}
          onSubmit={onSubmit}
          initialValues={initialValues}
          items={items}
        />
      )}
      <TopBar
        country={country}
        disabled={disabled || isSaving}
        handleChangeCountry={handleChangeCountry}
        handleOpenModal={handleOpenModal}
        handleSave={handleSave}
        handlePasteFromClipboard={handlePasteFromClipboard}
        items={items}
        isSaving={isSaving}
      />
      <div className={classes.list}>
        <List
          items={items}
          handleDelete={handleDelete}
          handleClone={handleClone}
          handleOpenModal={handleOpenModal}
          moveCard={moveCard}
          setScrollTo={setScrollTo}
        />
      </div>
    </Container>
  );
};

export default Layout;
